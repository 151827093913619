import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
// import Insights from "../molecules/Insights";

// Components
// import StaffingHeader from "../molecules/staffing/staffingHeader";
// import StaffingDescription from "../molecules/staffing/staffingDescription";

// import StaffingModelOfEngagement from "../molecules/staffing/staffingModelOfEngagement";
// // import ContactForm from "../../molecules/ContactForm";

// import ImageBanner from "../components/atoms/banner/imageBanner";
import { useStaticQuery, graphql } from "gatsby";

//images
// import headerImage from "../images/servicesassets/staffing.png";
import staffingImage from "../images/servicesassets/staffing/staffing.png";
import staffingMobImg from "../images/servicesassets/staffing/staffingcta.png";

import mobHeader from "../images/servicesassets/staffing/header-mob.png";
// import { Zoom } from "react-reveal";
// import SolutionsHeader from "../molecules/solutions/SolutionsHeader";
// import StaffingNewTimelineComponent from "../molecules/staffing/staffingNewTimelineComponent";
// import MobileIn from "../images/servicesassets/banners/StaffingMobileIn.png";
// import MobileEn from "../images/servicesassets/banners/StaffingMobileEn.png";
// import MobileJa from "../images/servicesassets/banners/StaffingMobileJa.png";
import backgroundImage from "../images/servicesassets/staffing/staffing_background.png";
import cost from "../images/servicesassets/staffing/cost.png";
import dev from "../images/servicesassets/staffing/dev.png";
import hiring_model from "../images/servicesassets/staffing/hiring_model.png";
import speed from "../images/servicesassets/staffing/speed.png";
import track_record from "../images/servicesassets/staffing/track_record.png";
import cand_vetting from "../images/servicesassets/staffing/cand_vetting.png";

// INDUSTRY IMAGES
import manufacture from "../images/servicesassets/staffing/manufacture.png";
import automotive from "../images/servicesassets/staffing/automotive.png";
// import medical from "../images/servicesassets/staffing/medical.png";
import aerospace from "../images/servicesassets/staffing/aerospace.png";

import { useLanguage } from "../languageContext";
import Staffheader from "../molecules/staffing/v2/header";
import PlainTextSection from "../molecules/staffing/v2/plainTextSection";
import StaffingServices from "../molecules/staffing/v2/staffingServices";
import TalentManagement from "../molecules/staffing/v2/talentManagement";
import HiringTech from "../molecules/staffing/v2/hiringTech";
import IndustryServe from "../molecules/staffing/v2/IndustryServe";
// import FooterFAQ from "../molecules/Footer/v2/FooterFAQ";
import FooterFAQs from "../molecules/Footer/v2/FooterFAQs";
const StaffingPage = () => {
  const data = useStaticQuery(graphql`
    query GetStaffingData {
      gcms {
        servicePages(where: { pageType: STAFFING }) {
          heading
          paragraph
          description
          timelineItems {
            heading
            paragraph
            listItem
            isList
          }
          technicalItems {
            heading
            paragraph
            icons {
              url
            }
          }
          sliderModals {
            tagline
            heading
            benefit
          }
          insightPages {
            shortDescription
            title
            slug
            image {
              url
            }
          }
        }
      }
    }
  `);
  const currentLanguage = useLanguage();
  const pageData = {
    header: {
      title:
        "Staffing Solutions for Engineering, Automotive, and Manufacturing",
      link: "/contactus/",
      linkText: "Talk to An Expert",
      image: backgroundImage,
      mobImage: mobHeader,
    },
    section1: {
      title1: `Your employees are the most important assets for your organization. `,
      title2: `It is imperative to hire the right candidate if your business depends on high-quality deliverables, efficiency, and productivity.`,
      para: `However, it is extremely challenging and time-consuming to find the right talent, particularly when it comes to hiring candidates with niche technical expertise or those in demanding engineering sectors such as automated driver assistance systems (ADAS), embedded software, and automotive product development.
        At Goken, we understand these challenges and offer comprehensive staffing solutions tailored to meet your unique needs. Whether you need contingent staffing or permanent staffing solutions, our team is equipped to provide the best recruitment services and ensure you have access to top-tier candidates quickly and efficiently. 
        Our expertise spans various industries, including engineering, automotive, and manufacturing. `,
    },
    services: {
      heading: `specialized  services `,
      list: [
        "Engineering Staffing",
        "Automotive Staffing ",
        "Customized Staffing",

        "Manufacturing Staffing",

        "ADAS engineering",

        "Embedded Software",
        "Aerospace",
        "Medical Device",
      ],
      para: `Our approach ensures that your business can thrive and succeed in today's competitive market with the right engineering talent on your team. Trust Goken for the best workforce solutions tailored to your business needs. `,
      ctaText: `Contact us today to learn more about how we can help you find the right talent. `,
      link: "/contactus/",
      linkText: "Contact Us",
    },
    talentManagement: {
      title: `Goken's Unique Approach to Talent Management `,
      para1: `Goken’s approach to staffing is effective across multiple industries. We apply the same rigorous candidate screening, selection, and hiring principles for our clients that we have successfully used internally at Goken for 15 years. Our unwavering focus on cultural fit, current skills, and future potential allows us to streamline search parameters effectively, resulting in more reliable outcomes.`,
      para2: `This unique and adaptable approach has enabled us to expand beyond our roots in the automotive industry to sectors such as aerospace, medical devices, consumer products, and many others. Our team excels at closing diverse engineering roles within organizations by connecting them with qualified candidates quickly. We engage with our clients through flexible hiring models, including direct hire and on contract, ensuring they have access to a high-performing team of quality engineering experts. This approach proves to be a cost-effective solution for businesses with fluctuating engineering resource needs. `,
      featureTitle: "Features of Goken’s Talent management solutions",
      featureList: [
        {
          title: `Comprehensive Candidate Vetting `,
          para: `Our thorough screening process ensures that candidates not only possess the necessary technical skills but also fit seamlessly into your organizational culture.`,
          image: cand_vetting,
        },
        {
          title: `Industry Expertise`,
          para: `With experience across a wide range of industries, our team  understands the unique talent requirements of each sector, enabling us to deliver tailored solutions. `,
          image: dev,
        },
        {
          title: `Speed and Efficiency`,
          para: `Our efficient processes and vast network allow us to connect you with top talent quickly, reducing time-to-fill and ensuring minimal disruption to your operations. `,
          image: speed,
        },
        {
          title: `Flexible Hiring Models`,
          para: `Whether you need permanent staff or temporary support, we offer flexible hiring models to suit your needs and budget.`,
          image: hiring_model,
        },
        {
          title: `Cost-Effective Solutions Expertise`,
          para: `Our services are designed to provide maximum value for your investment, helping you build a high-performing team without breaking the bank.`,
          image: cost,
        },
        {
          title: `Proven Track Record`,
          para: `With 20 years of successful talent management experience, we have a proven track record of delivering exceptional results for our clients.`,
          image: track_record,
        },
      ],
    },
    ctaBanner1: {
      title: `Partner with Goken for customized and high performing talent management solutions across a wide range of industries.`,
      para: `Contact us today to discover how we can help you build a strong team of engineering professionals `,
      link: "/contactus/",
      linkText: "Contact Us",
    },
    hiringMethod: {
      title: `Goken Strategic Hiring Methodology `,
      list: [
        {
          number: 1,
          title: `Client-Centric Approach `,
          methods: [
            "We meticulously understand your hiring needs and the unique dynamics of your workplace. ",
            "Crafting a compelling value proposition, we ensure alignment with your company’s values,  mirroring our internal success model.",
          ],
        },
        {
          number: 2,
          title: `Tailored Job Descriptions`,
          methods: [
            "We eschew generic descriptions, instead crafting tailored job profiles that resonate with top talent. ",
            "This tailored approach enables us to deploy targeted search strategies, ensuring optimal candidate matches.",
          ],
        },
        {
          number: 3,
          title: `Targeted Sourcing Strategies`,
          methods: [
            "Leveraging our extensive network, we identify and engage with top-tier talent nationwide.",
            "Our approach ensures you maintain control, providing transparency and flexibility throughout the sourcing process. ",
          ],
        },
        {
          number: 4,
          title: `Comprehensive Assessment Process`,
          methods: [
            "Candidates undergo a thorough tiered assessment, including cultural fit and technical evaluations. ",
            "Streamlining the process, we manage candidate submissions, interviews, and feedback, reducing the burden on hiring managers.",
          ],
        },
        {
          number: 5,
          title: `Day-One Productivity`,
          methods: [
            "Selected candidates undergo client-specific pre-engagement training, blending core skills with client activities.  ",
            "This ensures seamless integration into your team, maximizing productivity from day one.",
          ],
        },
      ],
    },
    industries: {
      title: `Staffing Solutions to transform industries`,
      para: `Partner with Goken for high performing talent management solutions across a wide range of industries.
Contact us today to discover how we can help you build a strong team of engineering professionals.`,
      list: [
        {
          title: `Automotive`,
          para: `Automotive industry has transformed in the past few years with autonomous and electrification leading the trend. These trends have caused a shift in the technology requirements needed for the majority workforce in the industry. Goken in its past 20 years has evolved working with major automotive companies and is completely aware of these changing needs of the industry.`,
          image: automotive,
        },
        {
          title: `Aerospace`,
          para: `Supply chain disruptions, reduction in demand, and reduction in workforce are some of the key challenges faced by aircraft manufacturers today. Goken’s ability to provide short-term and long-term engineering support during varying work load has been a boon to several manufacturers.`,
          image: aerospace,
        },
        // {
        //   title: `Medical Devices`,
        //   para: `Partner with Goken for customized and high performing  talent management solutions across a wide range of industries. Contact us today to discover how we can help you build a strong team of engineering professionals `,
        //   image: medical,
        // },
        {
          title: `Manufacturing`,
          para: `Adoption of digital transformation in manufacturing boosts productivity, reduces downtime and avoids fatal errors in plant environment. Technologies for data acquisition, processing and monitoring combined with application development are helping this transformation. Goken hires technical staff who develop products for the manufacturing sector.`,
          image: manufacture,
        },
      ],
      title2: `Locations Served through our staffing solutions`,
      locationsList: [
        "Ohio",
        "North Carolina",
        "South Carolina",
        "California",
        "Michigan",
        "Pune",
      ],
    },
    ctaBanner: {
      image: staffingImage,
      title: `Discover how our engagement models can benefit your business`,
      para1: `Experience the Goken difference. `,
      para2: `Contact us today to revolutionize your hiring process.`,
      linkText: "Learn More",
      link: "/insight/staffing-solutions-engineering-firms/",
      mobImage: staffingMobImg,
    },
    faqs: [
      {
        question: `What engineering solutions does Goken provide? `,
        answer: `<p class='margin-0'>At Goken, we provide a wide range of engineering solutions tailored to meet the specific needs of our clients. Our expertise includes but is not limited to</p>
<ul>
<li><b>Product Design and Development:</b> We offer end-to-end product design and development services, from concept ideation to final product realization. </li>
<li><b>Advanced Engineering Services:</b> Our team specializes in advanced engineering services such as ADAS, software development, data science, and more. </li>
<li><b>Automotive Engineering:</b> We have extensive experience in automotive engineering, including design, testing, and validation of automotive systems. </li></ul>`,
      },
      {
        question: `How does Goken help reduce product time-to-market? `,
        answer: `
          <p class='margin-0'>Goken employs several strategies to reduce product time-to-market, including: </p>
          <ul>
            <li><b>Efficient Design Processes:</b> We utilize efficient design processes to reduce design iteration time and accelerate product development. </li>
            <li><b>Collaborative Approach:</b> Our collaborative approach ensures that all stakeholders are involved from the beginning, reducing delays in decision-making. </li>
            <li><b>Advanced Technologies:</b> We leverage advanced technologies such as simulation and virtual prototyping to shorten development cycles. </li>
          </ul>
        `,
      },
      {
        question: `How does Goken optimize product development costs? `,
        answer: `
          <p class='margin-0'>Goken helps optimize product development costs through: </p>
          <ul>
            <li><b>Cost-effective Design: </b>We focus on designing cost-effective solutions without compromising on quality or performance. </li>
            <li><b>Supplier Collaboration: </b>We work closely with suppliers to identify cost-saving opportunities and streamline the supply chain. </li>
            <li><b>Value Engineering: </b> Our value engineering approach helps identify areas where costs can be reduced without sacrificing product quality. </li>
          </ul>
        `,
      },
      {
        question: `
        What makes Goken's engineering solutions unique?
       
        `,
        answer: `
        <p class='margin-0'>Goken's engineering solutions are unique because of our: </p>
         <ul>
            <li><b>Innovative Approach:</b> We approach every project with innovation and creativity, providing unique solutions to complex engineering challenges. </li>
            <li><b>Industry Expertise:</b> Our team consists of industry experts with years of experience in their respective fields, ensuring high-quality deliverables. </li>
            <li><b>Client-Centric Focus:</b> We prioritize the needs of our clients, ensuring that our solutions meet their specific requirements and expectations. </li>
          </ul>
        ,`,
      },
    ],
  };

  return (
    <Layout isNavWhite={true} hideMap hideFAQ>
      <SEO
        canonicalLink={"https://www.goken-global.com/staffing/"}
        title="Staffing Services: Staffing Solutions by Goken"
        description={
          "Connect with top talent and build your dream team with Goken's staffing services. Specializing in engineering, IT, and more. Click to start hiring!"
        }
        // meta="Staffing Services, Staffing, Staffing agency, temp agency, recruitment agencies, staffing agencies near me, employment agencies, staffing solutions"
        facebookTitle={
          "Goken | We Provide Engineering Staffing Solutions in India and USA"
        }
        facebookDescription={
          "Goken Engineering Staffing Solutions in India and USA makes sure that hiring the right candidate helped us to expand our roots in the auto industry to aircraft, medical devices, consumer products, and many other industries. For 15 years we have had a unique approach in the staffing and selection process."
        }
        keywords={"engineering staffing solutions"}
      />
      <Staffheader
        heading={pageData?.header.title}
        link={pageData?.header.link}
        linkText={pageData?.header.linkText}
        image={pageData?.header.image}
        mobImage={pageData?.header.mobImage}
      />
      <PlainTextSection
        bgclass="w-90-wrapper"
        title1={pageData?.section1.title1}
        title2={pageData?.section1.title2}
        paras={[pageData?.section1.para]}
        classSelector="semi-title fw-700"
      />
      <StaffingServices
        title={pageData?.services?.heading}
        list={pageData?.services?.list}
        para={pageData?.services?.para}
        ctaText={pageData?.services?.ctaText}
        linkText={pageData?.services?.linkText}
        link={pageData?.services?.link}
        pageClass="section-br-shadow"
      />
      <PlainTextSection
        bgclass="w-90-wrapper"
        contentClass="m-top-3rem"
        title1={pageData?.talentManagement?.title}
        paras={[
          pageData?.talentManagement?.para1,
          pageData?.talentManagement?.para2,
        ]}
      />
      {/* <TalentManagement
        title={pageData?.talentManagement?.featureTitle}
        list={pageData?.talentManagement?.featureList}
      /> */}
      <TalentManagement
        noborder
        title={pageData?.talentManagement?.featureTitle}
        list={pageData?.talentManagement?.featureList}
      />

      <PlainTextSection
        title1={pageData?.ctaBanner1?.title}
        link={pageData?.ctaBanner1?.link}
        linkText={pageData?.ctaBanner1?.linkText}
        paras={[pageData?.ctaBanner1?.para]}
        classSelector="semi-title"
        bgclass="color-white bg-en text-center"
        contentClass="w-90-wrapper cta-content-wrapper"
      />
      <HiringTech
        title={pageData?.hiringMethod?.title}
        list={pageData?.hiringMethod?.list}
      />
      <IndustryServe
        title={pageData?.industries?.title}
        para={pageData?.industries?.para}
        list={pageData?.industries?.list}
      />
      <StaffingServices
        titleClass={"text-center"}
        pageClass={""}
        title={pageData?.industries.title2}
        list={pageData?.industries?.locationsList}
        dotStyle
      />
      <Staffheader
        heading={pageData?.ctaBanner?.title}
        image={pageData?.ctaBanner?.image}
        link={pageData?.ctaBanner?.link}
        para1={pageData?.ctaBanner?.para1}
        para2={pageData?.ctaBanner?.para2}
        linkText={pageData?.ctaBanner?.linkText}
        mobImage={pageData?.ctaBanner?.mobImage}
        backgroundMobCta={true}
        // addClass="desktop-wrapper"
      />
      <FooterFAQs openAnswer={false} faqs={pageData?.faqs} />
      {/* <StaffingHeader
        heading={data.gcms.servicePages[0].heading}
        paragraph={data.gcms.servicePages[0].paragraph}
        image={bannerImg}
      ></StaffingHeader> */}
      {/*  <SolutionsHeader
        headingOne={data.gcms.servicePages[0].heading}
        // headingTwo={"Boost Team Productivity and Output"}
        paragraph={data.gcms.servicePages[0].paragraph}
        btnText="Talk To An Expert"
        page="staffing"
        mobileImage={
          currentLanguage === "en"
            ? MobileEn
            : currentLanguage === "in"
            ? MobileIn
            : currentLanguage === "ja"
            ? MobileJa
            : MobileEn
        }
        mobileAltText="Staffing Solutions Mobile Graphic"
      />

      <StaffingDescription
        headingOne="HOW GOKEN SOLVES TALENT MANAGEMENT CHALLENGES ACROSS MULTIPLE INDUSTRIES"
        description={data.gcms.servicePages[0].description}
        image={descImg}
      ></StaffingDescription>
      <StaffingNewTimelineComponent
        heading="OUR PROCESS"
        bannerService={"Staffing"}
        tagline="Goken provides access to a larger  talent pool of candidates and reduces the time to fill"
        items={data.gcms.servicePages[0].timelineItems}
        style={"staffing"}
      />

      <div className="staffing-moe">
        <StaffingModelOfEngagement
          heading="MODELS OF ENGAGEMENT"
          modals={data.gcms.servicePages[0].sliderModals}
        ></StaffingModelOfEngagement>
      </div>
      <div className="w-90-wrapper">
        <Insights
          showTitle={true}
          insights={data.gcms.servicePages[0].insightPages}
        ></Insights>
      </div>
      {/* <ContactForm
        showForm={true}
        typeOfForm="Product or Service Inquiry"
      ></ContactForm> */}
      {/* <ImageBanner
        heading="Learn more about our Staffing Solutions"
        btnText="Get in Touch"
        link="/contactus/"
      ></ImageBanner> */}
    </Layout>
  );
};

export default StaffingPage;
